import { useEffect } from 'react'
import type { NextPage } from 'next'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'

// Libs
import handleRoutePushWithQuery from '@/methods/url/handleRoutePushWithQuery'

// Components

// Constants
import { ROUTE_PATH } from '@/constants/routePath'
import { DEFAULT_LOCALE } from '@/constants/defaultLocale'

// Styles

// Variables

const NotFound: NextPage = () => {
  const router = useRouter()
  const { locale, query } = router

  useEffect(() => {
    handleRoutePushWithQuery({
      router,
      pathname: ROUTE_PATH.HOME,
      isReplace: true,
      locale: locale === 'default' ? DEFAULT_LOCALE : locale,
      query,
    })
  }, [])

  return <div />
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale as string, ['common'])),
    },
  }
}

export default NotFound
